<template>
  <div v-if="is_ready" class="pc">
    <div class="head-wrap">
      <div class="logo-box">
        <img :src="doLang('logo')" />
      </div>
      <div class="nt-box">
        <div v-for="(nav, index) in nav_info" :key="index">
          <a :href="nav.link">
            <p v-bind:class="{active:nav.active}">{{nav.title}}</p>
          </a>
        </div>
      </div>
    </div>
    <div class="body-wrap" v-if="version_info != null">
      <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide class="swiper-item"
                      v-for="(slide, index) in swiperSlides"
                      :key="index"
                      :style="{'max-height': innerHeight}">
          <img class="image"
               :src="slide.image"
               :style="{left: slide.style===1 ? '45px' : '15%' }" />
          <img class="bg"
               :src="slide.bg" />
          <div class="right-box"
               :style="{right: slide.style===1 ? '10%' : '15%' }">
            <!-- <p class="title">{{slide.title}}<span class="title_2">{{slide.title_2}}</span></p> -->
            <p class="title" v-html="slide.title"><span class="title_2">{{slide.title_2}}</span></p>
            <p class="content">{{slide.content}}</p>
            <img class="btn-img" data-cpu_bit="64"
                 :src="doLang('download_btn_pc_64')"
                  />
            <img class="btn-img" data-cpu_bit="32"
                 :src="doLang('download_btn_pc_32')"
                  />
            <div class="version_info_div">
              <p class="version_name">{{doLang('version_name')}}：{{version_info.version_name}}</p>
              <p class="update_time">{{doLang('update_time')}}：{{version_info.update_time | formatDate}}</p>
              <!-- <a class="b4_bit_link" href="http://">64位</a> -->
              <a v-for="i in version_info.multi" :key="i" class="multi_link" href="javascript:;" :data-id="i">{{doLang('multi')}}{{i + 1}}</a>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
    </div>
    <div class="info-wrap">
      <div v-if="is_show" class="content">
        <p class="title">{{doLang('business_cooperation')}}</p>
        <a href="mailto:BD@smartgaga.com"
           target="_blank">
          <p class="text">BD@smartgaga.com</p>
        </a>
        <!-- <a href="tel:15618072669">
          <p class="text">商务 | 15618072669</p>
        </a>
        <a href="tel:15618072669">
          <p class="text">广告 | 15618072669</p>
        </a> -->
      </div>
      <div v-if="is_show" class="content">
        <p class="title">{{doLang('customer_service')}}</p>
        <!-- <a href="mailto:marketing@VphoneGaGa.cn"
           target="_blank">
          <p class="text">marketing@VphoneGaGa.cn</p>
        </a> -->
        <div v-for="(qq, index) in qq_info" :key="index">
          <a :href="qq.link">
            <p class="text">{{qq.title}}</p>
          </a>
        </div>
        
        <!-- <a href="tel:800806564">
          <p class="text">电话 | 800806564</p>
        </a> -->
      </div>
      <div v-if="is_show" class="content">
        <p class="title">{{doLang('about_us')}}</p>
        <!-- <a href="javascript:;" @click="alertOnBuild">
          <p class="text">招聘信息</p>
        </a>
        <a href="javascript:;" @click="alertOnBuild">
          <p class="text">联系我们</p>
        </a> -->
        <p v-if="is_show_about" class="text">深圳市智多互动科技有限公司</p>
        <p v-if="is_show_about" class="text">公司地址：深圳市南山区高新南四道034号高新工业村W1A栋308</p>
        <p v-if="is_show_about" class="text">公司电话：075586957325</p>
      </div>
      <div v-if="is_show" class="content">
        <p class="title">{{doLang('follow_us')}}</p>
        <!-- <a href="http://www.baidu.com">
          <p class="text">微信公众号</p>
        </a>
        <a href="http://www.baidu.com">
          <p class="text">微信服务号</p>
        </a> -->
        <div v-for="(au, index) in about_us_info" :key="index">
          <a :href="au.link">
            <p class="text">{{au.title}}</p>
          </a>
        </div>
      </div>
    </div>
    <div v-if="is_show" class="friends-link">
      <p class="fl-title">{{doLang('friends_link')}}：</p>
      <div v-for="(friend, index) in friends_info" :key="index">
        <a :href="friend.link" target="_blank" rel="noopener noreferrer"><p>{{friend.title}}</p></a>
      </div>
      
    </div>
    <div class="foot-wrap">
      <div id="tk_div" class="content">
        <a :href="doLang('privacy_policy_url')" target="_blank" rel="noopener noreferrer"><p>{{doLang('privacy_policy')}}</p></a>
        <p>|</p>
        <a :href="doLang('user_protocol_url')" target="_blank" rel="noopener noreferrer"><p>{{doLang('user_protocol')}}</p></a>
      </div>
      <div v-if="is_show_about" class="content">
        <p>{{doLang('copyright_notice')}}</p>
        <p>|</p>
        <p><a href="http://beian.miit.gov.cn">粤ICP备19063526号</a></p>
        <!-- <p>|</p> -->
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502005107"><img src="https://static.gsxnj.cn/images/img/index/beianlogo.png" style="float:left;">粤公网安备 44030502005107号</a>
      </div>
    </div>
    <div class="language_set">
      <ul>
        <li><a href="/?language=zh-cn">中文</a></li>
        <li><a href="/?language=en-us">english</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { isNull, init, setBrowserLanguage, getBrowserLanguage, lang } from '@/utils/index'
import {request} from '../../utils/http'

let vm = null;

export default {
  name: 'pc',
  components: {
    swiper,
    swiperSlide,
    request
  },
  data () {
    return {
      qq_info: null,
      nav_info: null,
      friends_info: null,
      about_us_info: null,
      version_info: null,
      is_show_about: true,
      is_show: true,
      is_ready: false,
      default_download_channel: "default",
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 滑动速度
        speed: 800,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          click: function(e){
            console.log(e);
            if(e.target.className == 'btn-img'){
              var cpu_bit = e.target.getAttribute('data-cpu_bit');
              vm.downLoadApp(cpu_bit, -1);
            }else if(e.target.className == 'multi_link'){
              var id = e.target.getAttribute('data-id');
              vm.downLoadApp(32, id);
            }
          }
        }
      },
      swiperSlides: [
        {
          bg: 'https://static.gsxnj.cn/images/img/index/bg_1.51c52508.jpg',
          image: 'https://static.gsxnj.cn/images/img/index/img_1.351f2a05.png',
          title: '安全免费的虚拟手机',
          content: '全球领先的引擎  ·  完美极致的性能  ·  安卓用户的最爱'
        },
        {
          bg: 'https://static.gsxnj.cn/images/img/index/bg_2.9e941e6b.jpg',
          image: 'https://static.gsxnj.cn/images/img/index/img_2.d4a71d38.png',
          title: '放心升级安卓',
          title_2: '10.0',
          content: '独创兼容模式，完美运行不兼容安卓10应用'
        },
        {
          bg: 'https://static.gsxnj.cn/images/img/index/bg_3.59c317d8.jpg',
          image: 'https://static.gsxnj.cn/images/img/index/img_3.53111158.png',
          title: '应用游戏稳定多开',
          content: '多个微信同时聊，稳定不闪退'
        },
        {
          bg: 'https://static.gsxnj.cn/images/img/index/bg_4.a25f2b9f.jpg',
          image: 'https://static.gsxnj.cn/images/img/index/img_4.268ac550.png',
          title: '游戏挂机在线升级',
          content: '息屏不断网，睡觉可挂机'
        },
        {
          bg: 'https://static.gsxnj.cn/images/img/index/bg_5.b3ae4817.jpg',
          image: 'https://static.gsxnj.cn/images/img/index/img_5.63dfd77b.png',
          title: '极致性能媲美真机',
          content: '媲美真机的性能体验'
        }
      ]
    }
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      // let h = date.getHours();
      // h = h < 10 ? ('0' + h) : h;
      // let m = date.getMinutes();
      // m = m < 10 ? ('0' + m) : m;
      // let s = date.getSeconds();
      // s = s < 10 ? ('0' + s) : s;
      return y + '.' + MM + '.' + d;
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    innerHeight () {
      // console.log(window.innerHeight)
      return window.innerHeight + 'px'
    }
  },
  mounted () {},
  beforeCreate() {
    init().then((res) => {
      vm = this;
      this.$data.is_ready = true;
      var choose_language = this.$route.query.language;
      if(!isNull(choose_language)){
        setBrowserLanguage(choose_language);
      }
      console.log("language: " + getBrowserLanguage());
      request('https://www.gsxnj.cn/get_web_info.php', 'get', null, null)
        .then((res) => {
          var web_info;
          if(res.hasOwnProperty('zh-cn')){
            if(res.hasOwnProperty(getBrowserLanguage())){
              web_info = res[getBrowserLanguage()];
            }else{
              web_info = res['en-us'];
            }
          }else{
            web_info = res;
          }
          this.$data.qq_info = web_info.qq_info.list
          this.$data.nav_info = web_info.nav_info.list
          this.$data.about_us_info = web_info.about_us_info.list
          this.$data.friends_info = web_info.friends_info.list
        })
        .catch((e) => {console.log(e)})

      request('https://www.gsxnj.cn/get_swiper_set.php', 'get', null, null)
        .then((res) => {
          var url = window.location.href;
          url = url.replace('http://', '').replace('https://', '');
          url = url.split('/')[0];
          var url_split = url.split('.');
          url = url_split[url_split.length - 2] + '.' + url_split[url_split.length - 1];
          console.log(url);
          if(url != 'xuejijy1.cn'){
            url = getBrowserLanguage() == 'zh-cn' ? 'default' : 'default_en';
          }
          if(getBrowserLanguage() != 'zh-cn'){
            this.$data.is_show = false;
          }
          console.log(url);
          this.$data.is_show_about = res[url].is_show_about;
          this.$data.default_download_channel = res[url].default_download_channel;
          this.$data.swiperSlides = res[url]['pc'];
          request('https://www.gsxnj.cn/get_download.php', 'get', null, null)
          .then((res) => {
            this.$data.version_info = res
          })
          .catch((e) => {console.log(e)})
        })
        .catch((e) => {console.log(e)})
    })
    .catch((e) => {console.log(e)})
    
  },
  methods: {
    doLang (key) {
      return lang(key);
    },
    getChannel () {
      var channel = this.$route.query.from_channel;
      if(isNull(channel)){
        channel = this.$data.default_download_channel;
      }
      return channel;
    },
    downLoadApp (cpu_bit, id) {
      
      var download_url = '/download.php?channel=' + this.getChannel() + '&cpu_bit=' + cpu_bit
      if(id != -1){
        download_url += '&is_multi=1&muti_num=' + id
      }
      // 下载
      window.location.href = download_url
    },
    alertOnBuild () {
      alert("正在建设中！");
    }
  }
}
</script>
<style lang="less">
.pc {
  width: 100%;
  min-width: 1024px;
  height: 100%;
  min-height: 600px;
}
.head-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  padding: 10px 34px;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  background: #fff;
  .logo-box {
    width: 114px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nt-box {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
      padding: 0 23px;
      &.active {
        color: #1c26df;
      }
    }
  }
}
.body-wrap {
  width: 100%;
  font-size: 0;
  .swiper-item {
    position: relative;
    .image {
      position: absolute;
      top: 65px;
      left: 15%;
      height: 100%;
    }
    .bg {
      width: 100%;
      min-height: 100%;
    }
    .right-box {
      position: absolute;
      top: 30%;
      right: 15%;
      text-align: center;
      .multi_link{
        color: #ababab;
        border: 1px solid #888;
        border-radius: 7px;
        margin-left: 5px;
        padding: 1px 6px;
      }
      .title {
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(255, 255, 255, 1);
        line-height: 48px;
        margin-bottom: 15px;
        span {
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: 200;
          color: rgba(255, 255, 255, 1);
          line-height: 53px;
        }
      }
      .content {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 1);
        line-height: 15px;
        margin-bottom: 30px;
      }
      .btn-img {
        width: 180px;
        margin-right: 5px;
        cursor: pointer;
      }
      .version_info_div {
        font-size: 10px;
        color: #FFFFFF;
        margin: 10px;
        p {
          display: inline;
          margin: 5px;
        }
      }
    }
  }
  .swiper-pagination-bullet {
    background: #dedede;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #3250ff;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 20px;
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 15px;
  }
}
.info-wrap {
  background: #000000;
  // width: 100%;
  padding: 40px 20% 0px 20%;
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid #1b1b1b;
  .content {
    width: 180px;
    .title {
      color: #fff;
      font-size: 14px;
      margin-bottom: 16px;
    }
    .text {
      color: #cccccc;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}
.friends-link {
  background: #000000;
  // width: 100%;
  padding: 0px 20% 40px 20%;
  display: flex;
  border-bottom: 1px solid #1b1b1b;
  p.fl-title{
    color: #fff;
    font-size: 14px;
  }
  div {
    display: contents;
    a {
        color: #cccccc;
        font-size: 14px;
        padding: 0px 10px;
      }
  }
}
.foot-wrap {
  padding: 25px 0 10px;
  background: #000000;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4d4d4d;
    font-size: 12px;
    padding-bottom: 5px;
    p {
      padding: 0 20px;
    }
  }
  #tk_div {
    margin-left: -60px;
  }
}
.language_set{
  z-index: 100;
  position: fixed;
  bottom: 70px;
  right: 10px;
  background-color: #4aa6fc;
  -webkit-box-shadow: 0 1px 5px rgba(0,0,0,.2);
  box-shadow: 0 1px 5px rgba(0,0,0,.2);
  border-radius: 2px;
  ul{
    li{
      position: relative;
      text-align: center;
      overflow: hidden;
      padding: 15px 5px;
      color: white;
    }
    li:hover{
      background-color: white;
      color: black;
    }
  }
}
</style>
